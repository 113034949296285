/* eslint-disable no-unused-expressions */
import React from "react"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"
import Section from "../components/wwc/layout/Section/Section"
import Row from "../components/wwc/layout/Row/Row"
import Column from "../components/wwc/layout/Column/Column"
import Hero from "../components/wwc/media/Hero/Hero"
import { Link } from "gatsby"
import GatsbyImage from "../components/gatsby/image"

const IndexPage = ({ data, ...props }) => {
  const { metaData, hero, opportunity, whyWeDoIt } = data.homeJson

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta property="og:description" content={metaData.description} />
      </Helmet>

      <Hero data={hero} classes={`hero__home`} />

      <Section id="section-opportunity">
        <Row>
          <Column md={7} lg={6}>
            <h2 dangerouslySetInnerHTML={{ __html: opportunity.title }}></h2>
            <p
              dangerouslySetInnerHTML={{ __html: opportunity.description }}
            ></p>
            <Link className={`btn__cta--primary`} to={opportunity.link.url}>
              {opportunity.link.label}
            </Link>
          </Column>
          <Column md={5} lg={6} className="align-self-center">
            <div className="opportunity__image">
              <GatsbyImage
                imageName={opportunity.image.imageName}
                styles={{ maxWidth: "530px" }}
              />
            </div>
          </Column>
        </Row>
      </Section>

      <Section id="section-why-we-do-it">
        <Row>
          <Column md={7} className="order-md-2 align-self-center">
            <h2 dangerouslySetInnerHTML={{ __html: whyWeDoIt.title }}></h2>
            <p dangerouslySetInnerHTML={{ __html: whyWeDoIt.description }}></p>
          </Column>
          <Column md={5} className="order-md-1 align-self-center mobile-hidden">
            <GatsbyImage
              imageName={whyWeDoIt.image.imageName}
              styles={{ maxWidth: "390px" }}
            />
          </Column>
        </Row>
      </Section>
    </>
  )
}

export const query = graphql`
  query Index($locale: String) {
    siteJson(locale: { eq: $locale }) {
      ...SiteData
    }
    homeJson(locale: { eq: $locale }) {
      metaData {
        title
        description
      }
      ...HeroData
      opportunity {
        title
        description
        link {
          label
          url
        }
        image {
          imageName
          alt
        }
      }
      whyWeDoIt {
        title
        description
        image {
          imageName
          alt
        }
      }
    }
  }
`

export default IndexPage
