import React, { Component } from "react"
import t from "prop-types"
import Row from "../../layout/Row/Row"
import Column from "../../layout/Column/Column"
import GatsbyImage from "../../../gatsby/image"

import { graphql } from "gatsby"

class Hero extends Component {
  static propTypes = {
    classes: t.string,
    data: t.shape({
      title: t.string.isRequired,
      description: t.string.isRequired,
      img: t.shape({
        imageName: t.string.isRequired,
        alt: t.string.isRequired,
      }),
    }).isRequired,
  }

  render() {
    const { data, classes } = this.props

    return (
      <>
        <section id={`section-hero`} className={classes}>
          <div className="container">
            <Row>
              <Column className={`centered`}>
                <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
              </Column>
            </Row>
          </div>
          <Row>
            <Column className={`centered`}>
              <GatsbyImage imageName={data.img.imageName} className="hero__image--desktop" />
              <GatsbyImage imageName={data.img.imageNameMobile} className="hero__image--mobile" />
            </Column>
          </Row>
        </section>
      </>
    )
  }
}

export const query = graphql`
  fragment HeroData on HomeJson {
    hero {
      title
      description
      img {
        imageName
        imageNameMobile
        alt
      }
    }
  }
`

export default Hero
